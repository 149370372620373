import Home from './client/pages/Home';

import styles from './App.module.scss';
import './client/styles/global.scss';

/* Animate on Scroll */
import "aos/dist/aos.css";

function App() {
  return (
    <div className={styles.App}>
      <Home data-aos="fade-up" />
    </div>
  );
}

export default App;
