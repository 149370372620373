import React from 'react';
import cn from 'classnames';
import Langar from '../../assets/langar.png';
import Octopus from '../../assets/octopus.png';
import MobileOctopus from '../../assets/mobile-octopus.png';
import Lock from '../../assets/lock.png';
import Button from '../baseElements/Button/Button';

import styles from './Features.module.scss';
import useWidth from '../../utils/useWidth';

const Features = () => {
  const isMobile = useWidth();
  const octopus = isMobile === 'mobile' ? MobileOctopus : Octopus;
  return (
    <section className={styles.root}>
      <h2>Innovations Behind Every Feature</h2>
      <div className={styles.wrapper}>
        <div className={cn(styles.features, styles.first)} data-aos="fade-up">
          <img className={styles.image} src={octopus} alt='octopus' />
          <div className={styles['content-wrapper']}>
            <h3 className={styles.title}>
              Unlock the Power of <br /> Locked Liquidity
            </h3>
            <p className={styles.description}>
              Explore a streamlined, secure platform where both individuals and institutions can trade locked liquidity with confidence and ease.
            </p>
          </div>
        </div>
        <div className={cn(styles.features, styles.second)} data-aos="fade-up-right">
          <img className={styles.image} src={Langar} alt='langar' />
          <div className={styles['content-wrapper']}>
            <h3 className={styles.title}>Buy Locked Liquidity</h3>
            <p className={styles.description}>
              Explore and acquire premium locked liquidity securely, with instant access and strategic insights.
            </p>
            <Button className={styles.button}>Buy</Button>
          </div>
        </div>
        <div className={cn(styles.features, styles.third)} data-aos="fade-up-left">
          <img className={styles.image} src={Lock} alt='lock' />
          <div className={styles['content-wrapper']}>
            <h3 className={styles.title}>Sell Locked Liquidity</h3>
            <p className={styles.description}>
              List your locked liquidity effortlessly, connect with genuine buyers, and enjoy fast, flexible payouts.
            </p>
            <Button className={styles.button}>Sell</Button>
          </div>
        </div>

        <div className={styles['light-1']} />
        <div className={styles['light-2']} />
        <div className={styles['light-3']} />
      </div>

    </section>
  );
};

export default Features;
