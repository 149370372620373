import React, { useEffect, useRef } from 'react';
import Header from '../components/Header';
import Hero from '../components/HeroSection';
import Record from '../components/RecordSection';
import Features from '../components/FeaturesSection';
import About from '../components/AboutSection';
import Support from '../components/SupportSection';
import Footer from '../components/Footer';
import AOS from "aos";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  const statsRef = useRef(null);

  return (
    <div>
      <Header statsRef={statsRef} />
      <Hero />
      <Record statsRef={statsRef} />
      <Features />
      <About />
      <Support />
      <Footer />
    </div>
  );
};

export default Home;
