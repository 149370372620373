import React, { useEffect, useState } from 'react';
import Button from '../baseElements/Button';
import cn from 'classnames';
import Logo from '../../assets/logo-header.png';
import Menu from '../../assets/Menu.svg';
import Arrow from '../../assets/arrow-up.svg';
import Modal from '../Modal';
import GitBook from '../../assets/gitbook.js';
import MediumIcon from '../../assets/medium.js';
import XLogo from '../../assets/X_logo.js';
import Telegram from '../../assets/telegram.js';

import styles from './Header.module.scss';

const Header = ({ statsRef }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenMobile, setIsDropdownOpenMobile] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <header className={styles.header}>
        <a href='/' className={styles.logo}>
          <img src={Logo} alt='logo' />
        </a>
        <nav className={styles.nav}>
          <ul className={styles.list}>
            <li className={styles.item}>
              <a href='https://quid.gitbook.io/untitled/'>Docs</a>
            </li>
            <li className={styles.item}>
              <a href='#stats' onClick={(e) => {
                e.preventDefault();
                window.scroll({ top: statsRef.current.offsetTop, behavior: 'smooth' });
              }}>
                Stats
              </a>
            </li>
            <li
              className={cn(styles.item, { [styles.open]: isDropdownOpen })}
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
            >
              <span>Community</span>
              <img className={styles.arrow} src={Arrow} alt='arrow' />
              <div className={styles.dropdownWrapper}>
                <ul className={styles.dropdown}>
                  <li className={styles.linkWrap}>
                    <a className={styles.link} href='https://quid.gitbook.io/esquid'>
                      <span>GitBook</span>
                      <GitBook />
                    </a>
                  </li>
                  <li className={styles.linkWrap}>
                    <a className={styles.link} href='https://t.me/ESQUIDERC'>
                      <span>Telegram</span>
                      <Telegram />
                    </a>
                  </li>
                  <li className={styles.linkWrap}>
                    <a className={styles.link} href='https://x.com/Esquidity'>
                      <span>X</span>
                      <XLogo />
                    </a>
                  </li>
                  <li className={styles.linkWrap}>
                    <a className={styles.link} href='https://medium.com/@Esquid'>
                      <span>Medium</span>
                      <MediumIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>

        <div className={styles.menu} onClick={openModal}>
          <img src={Menu} alt='menu' />
        </div>
        <a href='https://www.esquidescrow.app/escrow'>
          <Button className={styles.button}>Launch Escrow</Button>
        </a>
      </header>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className={styles['modal-content']}>
          <div className={styles.logo}>
            <img src={Logo} alt='logo' />
          </div>
          <nav className={styles.nav}>
            <ul className={styles.list}>
              <li className={styles.item}>
                <a href='https://quid.gitbook.io/untitled/'>Docs</a>
              </li>
              <li className={styles.item}>
                <a href='#stats' onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(false);
                  window.scroll({ top: statsRef.current.offsetTop, behavior: 'smooth' });
                }}>
                  Stats
                </a>
              </li>
              <li
                className={cn(styles.item, { [styles.open]: isDropdownOpenMobile })}
                onClick={() => setIsDropdownOpenMobile(!isDropdownOpenMobile)}
              >
                <span>Community</span>
                <img
                  className={styles.arrow}
                  src={Arrow}
                  alt='arrow'
                />

                <ul
                  className={styles.dropdown}
                >
                  <li className={styles.link}>
                    <a href='https://quid.gitbook.io/esquid'>
                      <span>GitBook</span>
                      <GitBook />
                    </a>
                  </li>
                  <li className={styles.link}>
                    <a href='https://t.me/ESQUIDERC'>
                      <span>Telegram</span>
                      <Telegram />
                    </a>
                  </li>
                  <li className={styles.link}>
                    <a href='https://x.com/Esquidity'>
                      <span>X</span>
                      <XLogo />
                    </a>
                  </li>
                  <li className={styles.link}>
                    <a href='https://medium.com/@Esquid'>
                      <span>Medium</span>
                      <MediumIcon />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </Modal>
    </>
  );
};

export default Header;
