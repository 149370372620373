/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Octopus from '../../assets/logo-footer.png';
import LeftFoot from '../../assets/left-foot.png';
import RightFoot from '../../assets/right-foot.png';

import styles from './Footer.module.scss';
import useWidth from '../../utils/useWidth';
import GitBook from '../../assets/gitbook.js';
import Telegram from '../../assets/telegram.js';
import XLogo from '../../assets/X_logo.js';
import MediumIcon from '../../assets/medium.js';

const Footer = () => {
  const isMobile = useWidth();
  return (
    <footer>
      <div className={styles.content}>
        <ul className={styles.links}>
          <li className={styles.item}>
            <a className={styles.link} href='#'>
              Terms & Conditions
            </a>
          </li>
          <li className={styles.item}>
            <a className={styles.link} href='#'>
              Referral Terms
            </a>
          </li>
        </ul>

        <div className={styles.logo}>
          <a href='/'>
            <img src={Octopus} alt='logo' />
          </a>

          <div className={styles.textMobile}>
            © 2024 ESQUID. All Rights Reserved.
            <br />
            <span style={{ fontStyle: 'italic' }}>
              Empowering secure transactions in the world of locked liquidity.
            </span>
          </div>
        </div>

        <div className={styles['external-links']}>
          <a href='https://quid.gitbook.io/esquid'>
            <GitBook />
          </a>
          <a href='https://t.me/ESQUIDERC'>
            <Telegram />
          </a>
          <a href='https://x.com/Esquidity'>
            <XLogo />
          </a>
          <a href='https://medium.com/@Esquid'>
            <MediumIcon />
          </a>
        </div>
      </div>

      <div className={styles.text}>
        © 2024 ESQUID. All Rights Reserved.
        <br />
        {/* italic */}
        <span style={{ fontStyle: 'italic' }}>
          Empowering secure transactions in the world of locked liquidity.
        </span>
      </div>

      <img className={styles.left} src={LeftFoot} alt='foot' />
      <img className={styles.right} src={RightFoot} alt='foot' />

      <div className={styles.light} />
      <div className={styles.lightFill} />
    </footer>
  );
};

export default Footer;
