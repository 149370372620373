import React from 'react';
import heroImage from '../../assets/hero-image.png';
import Button from '../baseElements/Button';

import styles from './Hero.module.scss';

const Hero = () => {
  return (
    <section className={styles.hero}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h2 className={styles['content-title']}>
            Empower Your
            <br /> Investments with <br />
            <span className={styles['content-title-primary']}>Secure Escrow</span>
          </h2>
          <p className={styles['content-description']}>
            First ever Decentralized Escrow for Locked Liquidity
          </p>
          <a href='https://quid.gitbook.io/untitled/'>
            <Button className={styles.button}>Learn More</Button>
          </a>
        </div>
      </div>

      <div className={styles.image}>
        <img src={heroImage} alt='hero-image' />
      </div>
    </section>
  );
};

export default Hero;
