import React, { useState } from 'react';
import useWidth from '../../utils/useWidth';
import CountUp from 'react-countup';
import { Waypoint } from 'react-waypoint';

import styles from './Record.module.scss';

const Record = ({ statsRef }) => {
  const isMobile = useWidth();
  const length = isMobile === 'mobile' ? 6 : 9;

  const svnArr = Array.from({ length: length }, (_, index) => (
    <div className={styles.line} key={index}></div>
  ));

  // State to track if the waypoint has been passed
  const [startCounting, setStartCounting] = useState(false);

  return (
    <section className={styles.Record} ref={statsRef}>
      <div className={styles.content}>
        <h2 className={styles.title} data-aos="fade-up">Stats</h2>
        <div className={styles.wrapper}>
          <Waypoint onEnter={() => setStartCounting(true)} />
          <div className={styles.box}>
            <p><CountUp start={0} end={startCounting ? 30 : 0} duration={5} />%</p>
            <span>
              Annual growth rate of 30% in locked liquidity markets since 2020.
            </span>
          </div>
          <div className={styles.box}>
            <p><CountUp start={0} end={startCounting ? 100 : 0} duration={5} />B</p>
            <span>Nearly 100 billion in liquidity currently locked across all DeFi platforms.</span>
          </div>
          <div className={styles.box}>
            <p><CountUp start={0} end={startCounting ? 57 : 0} duration={5} />%</p>
            <span>
              DeFi projects face liquidity issues due to
              slow adaptability.
            </span>
          </div>
        </div>
      </div>
      <div className={styles['line-wrapper']}>{svnArr}</div>
    </section>
  );
};

export default Record;
