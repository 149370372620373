import React from 'react';
import cn from 'classnames';
import miniOctopus from '../../assets/mini-octopus.png';
import Ethereum from '../../assets/Ethereum.png';

import styles from './About.module.scss';

const About = () => {
  return (
    <section className={styles.about}>
      <h2 className={styles.head} data-aos="fade-up">Who Can Use Esquid</h2>
      <div className={styles.wrapper}>
        <div className={cn(styles.box, styles.first)}>
          <img className={styles.image} src={Ethereum} alt='Ethereum' data-aos="fade-right" />
          <h3 className={styles.title}>Individual Investors</h3>
          <p className={styles.description}>
            Unlock the potential of your portfolio by engaging directly in the buying and selling of locked liquidity, tailored to individual investment strategies.
          </p>
        </div>
        <div className={cn(styles.box, styles.second)}>
          <img className={styles.image} src={miniOctopus} alt='mini-octopus' data-aos="fade-left" />
          <h3 className={styles.title}>DeFi Projects</h3>
          <p className={styles.description}>
            Utilize ESQUID to manage your project’s liquidity more effectively, facilitating smoother token launches and liquidity transitions.
          </p>
        </div>

        <div className={styles['light-1']} />
      </div>
    </section>
  );
};

export default About;
